// import { acquireAccessToken } from "./Authentication/acquire_token";
import { CONFIG_URL } from "./Settings/envConstants";
const apiUrl = CONFIG_URL.apiUrl;

const ChatAPIService = {

postRequest: async (userPrompt, sessionId, userId, controllerSignal) => {
    
    try {
      // const token = await acquireAccessToken()
      const response = await fetch(apiUrl+'chat/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: userId,
          userPrompt: userPrompt,
          sessionId: sessionId,
          stream: true
        }),
        signal: controllerSignal,
      });

      if (!response.ok) {
        // throw new Error('Request failed');
        throw new Error('An error occurred. Please try again');
      }

     return response;
    } catch (error) {
      console.error('Error:', error);
      if(error.name === 'AbortError') {
        localStorage.setItem('storeChat', false)
        throw "Oops, Request cancelled"
      } else {
        throw error;
      }
    }
  },
};

export default ChatAPIService;
